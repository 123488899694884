import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { Contato } from '../contato';

@Injectable({
  providedIn: 'root'
})
export class ContatoService {

  constructor(private http: HttpClient) {}

  salvarContato(contato: Contato | any): Observable<Contato> {
    return this.http.post(`${environment.api}/v1/aplicativo/salvar-contato`, contato) as Observable<Contato>;
  }

}
