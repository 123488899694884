import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ExcluirContaService } from '../service/excluir-conta.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.scss']
})
export class EmailComponent {

  email: string = "";

  constructor(private router: Router, 
              private formBuilder: FormBuilder,
              private message: MessageService,
              private activatedRoute : ActivatedRoute) { }

  ngOnInit(): void {
    this.email = this.activatedRoute.snapshot.paramMap.get("email")||"";
  }


}
