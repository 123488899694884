<div class="main-content">
    <header class="header">
        <img src="/assets/img/topo.gif">
    </header>
    <br>
    <div class="center card flex justify-content-center">
        <p-card>
            <p style="margin: 0px 0px 10px;">Por meio deste canal será possível excluir sua conta no aplicativo 190 DF.&nbsp;</p>

            <p style="margin: 0px 0px 10px;">
                Para iniciar, basta você colocar seu CPF no campo abaixo que enviaremos o passo a passo no seu email cadastrado<br>                
            </p>

            <mat-card class="mb-25 create-project-card atendimento-card">
                <form [formGroup]="form">
                    <div class="row">
                        <div class="col-lg-4 col-md-4 col-sm-4">
                            <label class="d-block mb-12 fw-semibold gray-color">CPF <span>*</span></label>
                            <mat-form-field appearance="fill">
                                <i class="ri-user-line"></i>
                                <mat-label>CPF</mat-label>
                                <input matInput formControlName="cpf" required mask="CPF_CNPJ" maxlength="14">
                            </mat-form-field>
                        </div>
                    </div>
                </form>
            </mat-card>

            
            <div class="d-flex flex-column align-items-center m-25">
                
                <p class="m-0">
                    <p-button (click)="confirmar()"> Confirmar </p-button>
                </p>
            </div>
        </p-card>
    </div>
</div>