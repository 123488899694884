<p-toast position="top-right" [baseZIndex]="1060"></p-toast>
<div
    [ngClass]="{'blank-page': router.url.includes('') || router.url.includes('/externo')}"
    [class.dark-theme]="themeService.isDark()"
    [class.card-borderd-theme]="themeService.isCardBorder()"
    [class.card-border-radius]="themeService.isCardBorderRadius()"
    [class.rtl-enabled]="themeService.isRTLEnabled()"
>
    <div
        class="main-content d-flex flex-column"
        [ngClass]="{'active': isToggled}"
        [class.right-sidebar]="themeService.isRightSidebar()"
        [class.hide-sidebar]="themeService.isHideSidebar()"
    >
        <router-outlet></router-outlet>
        <div class="flex-grow-1"></div>
    </div>
    <!-- <app-customizer-settings></app-customizer-settings> -->
</div>