import { Component, OnInit } from '@angular/core';
import { Validators, FormControl, FormGroup } from '@angular/forms';
import { ContatoService } from './service/contato.service';
import { LoadingService } from 'src/app/service/loading/loading-service.service';
import { MessageService } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { Contato } from './contato';

@Component({
  selector: 'app-contato',
  templateUrl: './contato.component.html',
  styleUrls: ['./contato.component.scss']
})
export class ContatoComponent {

  constructor(
    private service: ContatoService,
    private loading: LoadingService,
    private messageService: MessageService,
    private router: Router,
    private route: ActivatedRoute,
    private message: MessageService
  ) {
  }

  usuarioId!: number;

  formContato = new FormGroup({
    tipoContato: new FormControl('', Validators.required),
    titulo: new FormControl('', Validators.required),
    descricao: new FormControl('', Validators.required),
    nomeUsuarioExterno: new FormControl('', Validators.required),
    telefoneUsuarioExterno: new FormControl('', Validators.required),
    emailUsuarioExterno: new FormControl('', Validators.required),
  });

  contato = [
    {id: 1, descricao: 'Elogio'},
    {id: 2, descricao: 'Sugestão'},
    {id: 3, descricao: 'Reclamação'},
    {id: 4, descricao: 'Solicitação'}
  ];

  salvarContato() {
    let contato = this.formContato.getRawValue() as Contato;


    this.loading.show();
    this.service.salvarContato(contato)
    .subscribe({
      next: (data: Contato) => {
        this.message.add({
          severity: 'success',
          summary: "Sucesso",
          detail: "Sugestão Enviada!"
        });
        this.formContato.reset();
      },
      error: (data: any) => {
        console.error(data);
      },
      complete: () => this.loading.hide()
    })
  }
}
