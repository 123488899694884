<div class="main-content">
    <header class="header">
        <img src="/assets/img/topo.gif">
    </header>
    <br>
    <div class="center card flex justify-content-center">
        <p-card>
            <p style="margin: 0px 0px 10px;">Para excluir sua conta do aplicativo 190 DF basta clicar no botão confirmar.&nbsp;</p>
           
            <div class="d-flex flex-column align-items-center m-25">
                
                <p class="m-0">
                    <p-button (click)="confirmar()"> Confirmar </p-button>
                </p>
            </div>
        </p-card>
    </div>
</div>