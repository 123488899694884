<div class="main-content">
    <header class="header">
        <img src="/assets/img/topo_fale_conosco.gif">
    </header>
    <br>
    <mat-card class="mb-25 atendimento-card">
        <mat-card-header class="mb-30">
            <h5 class="mb-0">Envie sua sugestão</h5>
        </mat-card-header>
        <mat-card-content>
            <div class="mat-elevation-z8 atendimento-mat-table alinharDireita">
                <form [formGroup]="formContato" style="display: grid;" (submit)="salvarContato()">
                    <label for="nome">Nome</label>
                    <input id="nome" class="mb-25" type="text" pInputText formControlName="nomeUsuarioExterno" maxlength="100"/>

                    <label for="telefone">Telefone</label>
                    <input id="telefone" class="mb-25" type="text" pInputText formControlName="telefoneUsuarioExterno" maxlength="20" mask="(00) 00000-0000||(00) 0000-0000"/>

                    <label for="email">E-mail</label>
                    <input id="email" class="mb-25" type="email" pInputText formControlName="emailUsuarioExterno" maxlength="100"/>

                    <label for="tipo">Tipo de contato</label>
                    <p-dropdown id="tipo" class="mb-25" formControlName="tipoContato" [options]="contato" optionLabel="descricao" placeholder="Selecione um tipo"></p-dropdown>

                    <label for="titulo">Título</label>
                    <input id="titulo" class="mb-25" type="text" pInputText formControlName="titulo"/>

                    <label for="descricao">Descrição</label>
                    <textarea id="descricao" class="mb-25" rows="10" cols="30" pInputTextarea formControlName="descricao"></textarea>

                    <p-button class="d-flex justify-content-end" label="Enviar" type="submit" [disabled]="this.formContato.invalid"></p-button>

                </form>
            </div>
        </mat-card-content>
    </mat-card>
</div>
