<div class="main-content">
    <header class="header">
        <img src="/assets/img/topo.gif">
    </header>
    <br>
    <div class="center card flex justify-content-center">
        <p-card>
            <p style="margin: 0px 0px 10px;">Foi enviado um email para {{email}}, nele você poderá finalizar seu cadastro.&nbsp;</p>

            <p style="margin: 0px 0px 10px;">
                Você tem 60 minutos para concluir o encerramento da sua conta no APP 190 DF<br>                
            </p>

        </p-card>
    </div>
</div>