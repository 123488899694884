import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ExcluirContaService } from '../service/excluir-conta.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-finalizar',
  templateUrl: './finalizar.component.html',
  styleUrls: ['./finalizar.component.scss']
})
export class FinalizarComponent {

  form: FormGroup;
  id: string = "";
  token: string = "";

  constructor(private router: Router, 
              private formBuilder: FormBuilder,
              private route: ActivatedRoute,
              private excluirContaService: ExcluirContaService,
              private message: MessageService) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      var p = params['c'];
      const myParam = p.split(",");

      this.id = myParam[0];
      this.token = myParam[1];
      
      this.excluirContaService.validarExclusao(this.id, this.token).subscribe({
        next: (retorno) =>{
          if(!retorno){
            this.router.navigate(['/inicio']);
          }
        }
      })
    });

    this.form = this.formBuilder.group({
      cpf: ['', [Validators.required]]
    });
  }

  confirmar() {
    this.excluirContaService.finalizarExclusão(this.id, this.token).subscribe({
      next: () =>{
        this.message.add({
          severity: 'success',
          summary: "Sucesso",
          detail: "Conta encerrada!"

        });
          this.router.navigate(['/inicio']);
      },
      error: (error: any) => {
        console.log(error)
      }
    })
  }

}
