import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ExcluirContaService } from '../service/excluir-conta.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.scss']
})
export class InicioComponent {

  form: FormGroup;

  constructor(private router: Router, 
              private formBuilder: FormBuilder,
              private excluirContaService: ExcluirContaService,
              private message: MessageService) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      cpf: ['', [Validators.required]]
    });
  }

  confirmar() {
    let cpf = this.form.controls.cpf.value
    this.excluirContaService.iniciarExclusao(cpf).subscribe({
      next: (retorno) =>{
        this.router.navigate(['/email', retorno.email]);
      },
      error: (error: any) => {
        this.message.add({
          severity: 'error',
          summary: "Erro",
          detail: error.error.message
        });
      }

    })
  }

}
