import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { InternalErrorComponent } from './components/common/internal-error/internal-error.component';
import { NotFoundComponent } from './components/common/not-found/not-found.component';
import { InicioComponent } from './components/atendimento/inicio/inicio.component';
import { FinalizarComponent } from './components/atendimento/finalizar/finalizar.component';
import { EmailComponent } from './components/atendimento/email/email.component';
import { ContatoComponent } from './components/atendimento/contato/contato.component';

const routes: Routes = [

    {path: '', redirectTo: 'inicio', pathMatch: 'full'},
    {path: 'inicio', component: InicioComponent},
    {path: 'excluir-conta', component: InicioComponent},
    {path: 'finalizar', component: FinalizarComponent},
    {path: 'email/:email', component: EmailComponent},
    {path: 'contato', component: ContatoComponent},
    {path: 'info/:mensagem', component: InternalErrorComponent},
    {path: '**', component: NotFoundComponent} // This line will remain down from the whole pages component list
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }