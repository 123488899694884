import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";

@Injectable({
    providedIn: 'root',
})
export class ExcluirContaService {
    private url = environment.api + '/v1/aplicativo/';

    constructor(
        private httpClient: HttpClient
      ) {}

    iniciarExclusao(cpf: string): Observable<any> {
        return this.httpClient.post(this.url + "iniciar-cancelamento-conta/" + cpf, {});
    }

    validarExclusao(id: string, token: string): Observable<boolean> {
        return this.httpClient.get<boolean>(this.url + "validar-exclusao/" + id + "/" + token);
    }

    finalizarExclusão(id: string, token:string): Observable<any> {
        return this.httpClient.delete(this.url + "finalizar-cancelamento-conta/" + id + "/" + token);
    }
}